import React, {useEffect, useState} from 'react'

import AutocompleteStyled from "../common/AutocompleteStyled";
import {navigate} from "gatsby";

import SearchStyled from "../common/SearchStyled";
import ButtonStyled from "../common/ButtonStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import { deleteUser, getUserById, UserDto } from '../../api/UserManagementApi';
import { Card, CardActions, CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Header from '../common/Header';
import ConfirmDialog from '../common/ConfirmDialog';

interface UserDetailProps{
    id: number,
    style?:any
}

export default function UserDetail(props: UserDetailProps ){
    const classes = useStyles();
    const { t } = useTranslation();

    const [user, setUser] = useState<UserDto>(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

    const deleteThisUser = () =>{
        deleteUser(props.id).then(res =>{
            navigate("/users")
        })
    }

    useEffect(() => {
        getUserById(props.id).then(res => {
            setUser(res)
        }).catch((err)=>{navigate("/404")})
    },[]);

    return (
        <Paper className={classes.root} style={props.style}>
        {(user!= null) ?
        <Card variant="outlined">
            <Header title={t("USERDETAIL")} titleTypographyProps={{variant:"h5", align:"center"}}/>
                <CardContent>
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("USERNAME")}
                    </Typography>
                    <Typography variant="body1" className={classes.fieldContent}>
                        {user.username}
                    </Typography>
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("FIRSTNAME")}
                    </Typography>
                    <Typography variant="body1" className={classes.fieldContent}>
                        {user.firstName}
                    </Typography>
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("SURNAME")}
                    </Typography>
                    <Typography variant="body1" className={classes.fieldContent}>
                        {user.lastName}
                    </Typography>
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("ROLES")}
                    </Typography>
                        <Table style={{width: 400}} aria-label={t("ROLES")}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold", width: 200, padding: 5}}>{t("NAME")}</TableCell>
                                    <TableCell style={{ fontWeight: "bold", width: 200, padding: 5}}>{t("DESCRIPTION")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {user.roles.map(role => (
                                    <TableRow key={role.id}>
                                        <TableCell style={{ width: 200, padding: 5 }}>{role.name}</TableCell>
                                        <TableCell style={{ width: 200, padding: 5 }}>{role.description}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    <br/>
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("MFAACTIVATED")}
                    </Typography>
                    <Typography variant="body1" className={classes.fieldContent}>
                        {user.mfaActivated==true ? t("YES") : t("NO") }
                    </Typography>
                </CardContent>

            <CardActions className={classes.cardActions}>
                <ButtonStyled
                    text={t("DELETEUSER")}
                    onClick={()=>setDeleteDialogOpen(true)}/>
                <ConfirmDialog
                    title={t("DELETEUSER")}
                    open={deleteDialogOpen}
                    onClose={()=>setDeleteDialogOpen(false)}
                    onConfirm={deleteThisUser}
                >
                    {t("DELETETHISUSER",{username: user.username})}
                </ConfirmDialog>
                <ButtonStyled
                    text={t("EDIT")}
                    onClick={() => navigate("/users/"+props.id+"/edit")}/>
            </CardActions>
        </Card>
        : <div/>}
        </Paper>
    )
}